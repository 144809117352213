
import { Component, Vue } from "vue-property-decorator";
import NotAuthHeader from "@/components/NotAuthHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import Cognito from "@/plugins/cognito";
import FacilityService from "@/services/facilities";

@Component({
  name: "ResetPasswordSend",
  components: {
    NotAuthHeader,
    ManagementFooter,
  },
})
export default class ResetPasswordSend extends Vue {
  private email = "";

  private loading = false;

  private async submit() {
    try {
      this.loading = true;
      await Cognito.forgetPassword(this.email);
      await FacilityService.sendResetPasswordLink(this.email);
      this.$router.push({ name: "ResetPasswordSendConfirmed" });
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }
}
